import React from "react";
import { Link } from "react-router-dom";
import "../css/faq.css";

const Faq: React.FC = () => {
  return (
    <div>
      <section className="faq">
        <div className="faq-top">
          <h1>Got Questions?</h1>
          <img
            src={require("../img/signal wave 2.png")}
            alt="signal"
            className="img1"
          />
          <img
            src={require("../img/signal wave 2.png")}
            alt="signal"
            className="img2"
          />
          <p>Find answers in our FAQs</p>
        </div>
        <div className="faq-item">
          <div className="faq-list">
            <h4>1. What can i expect from xHero Rewards?</h4>
            <img
              src={require("../img/arrow.png")}
              alt=""
              className="toggle-arrow"
            />
            <p className="hidden-p">
              The rewards and recognition platform <strong>DIP</strong>. It not
              only shape or engage your company but build trust, culture,
              respect and productivity within the team. The idea is to provide
              employees with the best employee experience and empower them to
              build a workforce that thrives together <strong>xHero</strong>{" "}
              does exactly that and improves your company's overall engagement,
              productivity, retention, and more.
            </p>
          </div>
          <div className="faq-list">
            <h4>
              2. Will there be a change in pricing upon renewal of the
              agreement?
            </h4>
            <img
              src={require("../img/arrow.png")}
              alt=""
              className="toggle-arrow"
            />
            <p className="hidden-p">
              The rewards and recognition platform <strong>DIP</strong>. It not
              only shape or engage your company but build trust, culture,
              respect and productivity within the team. The idea is to provide
              employees with the best employee experience and empower them to
              build a workforce that thrives together <strong>xHero</strong>{" "}
              does exactly that and improves your company's overall engagement,
              productivity, retention, and more.
            </p>
          </div>
          <div className="faq-list">
            <h4>
              3. Will there be any additional commercials involved on any
              customizations of the module?
            </h4>
            <img
              src={require("../img/arrow.png")}
              alt=""
              className="toggle-arrow"
            />
            <p className="hidden-p">
              The rewards and recognition platform <strong>DIP</strong>. It not
              only shape or engage your company but build trust, culture,
              respect and productivity within the team. The idea is to provide
              employees with the best employee experience and empower them to
              build a workforce that thrives together <strong>xHero</strong>{" "}
              does exactly that and improves your company's overall engagement,
              productivity, retention, and more.
            </p>
          </div>
          <div className="faq-list">
            <h4>4. What type of recognition are available?</h4>
            <img
              src={require("../img/arrow.png")}
              alt=""
              className="toggle-arrow"
            />
            <p className="hidden-p">
              The rewards and recognition platform <strong>DIP</strong>. It not
              only shape or engage your company but build trust, culture,
              respect and productivity within the team. The idea is to provide
              employees with the best employee experience and empower them to
              build a workforce that thrives together <strong>xHero</strong>{" "}
              does exactly that and improves your company's overall engagement,
              productivity, retention, and more.
            </p>
          </div>
          <div className="faq-list">
            <h4>5. What is the standard validity of the agreement</h4>
            <img
              src={require("../img/arrow.png")}
              alt=""
              className="toggle-arrow"
            />
            <p className="hidden-p">
              The rewards and recognition platform <strong>DIP</strong>. It not
              only shape or engage your company but build trust, culture,
              respect and productivity within the team. The idea is to provide
              employees with the best employee experience and empower them to
              build a workforce that thrives together <strong>xHero</strong>{" "}
              does exactly that and improves your company's overall engagement,
              productivity, retention, and more.
            </p>
          </div>
          <div className="faq-list">
            <h4>6. What is your cancellation policy?</h4>
            <img
              src={require("../img/arrow.png")}
              alt=""
              className="toggle-arrow"
            />
            <p className="hidden-p">
              The rewards and recognition platform <strong>DIP</strong>. It not
              only shape or engage your company but build trust, culture,
              respect and productivity within the team. The idea is to provide
              employees with the best employee experience and empower them to
              build a workforce that thrives together <strong>xHero</strong>{" "}
              does exactly that and improves your company's overall engagement,
              productivity, retention, and more.
            </p>
          </div>
        </div>
      </section>

      <svg
        viewBox="0 0 1440 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg2"
      >
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#ffffff"
        />
      </svg>
      <section className="look">
        <div className="look-item">
          <h3>
            Looking to stay ahead of the circle? <br />
            Check out our blog
          </h3>
          <p>
            We've got the best blog to get you started and stay ahead of latest
            we've got to offer both for openings careers and know about xHero
            more.
          </p>
          <a href="#">Check Out</a>
        </div>
        <img src="./img/OG.png" alt="OG" />
      </section>
      <svg
        viewBox="0 0 1440 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svg3"
      >
        <path
          d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
          fill="#0D0D0D"
        />
      </svg>
    </div>
  );
};

export default Faq;
