import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layouts/layout";
import About from "../components/About";
import Home from "../components/Home";
import Pricing from "../components/Pricing";
import FAQ from "../components/Faq";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import Login from "../components/Login";
import Signup from "../components/Signup";
import ForgotPassword from "../components/ForgotPassword";
import VerifyEmail from "../components/EmailVerified";
import Verified from "../components/Verified";
import ResetPassword from "../components/ResetPassword";
import Platforms from "../components/PlatForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout title="Home" favicon="../img/logo.png">
        <Home />
      </Layout>
    ),
  },
  {
    path: "/about",
    element: (
      <Layout title="About" favicon="../img/logo.png">
        <About />
      </Layout>
    ),
  },
  {
    path: "/pricing",
    element: (
      <Layout title="Pricing" favicon="../img/logo.png">
        <Pricing />
      </Layout>
    ),
  },
  {
    path: "/blog",
    element: (
      <Layout title="Blog" favicon="../img/logo.png">
        <Blog />
      </Layout>
    ),
  },
  {
    path: "/faq",
    element: (
      <Layout title="FAQ" favicon="../img/logo.png">
        <FAQ />
      </Layout>
    ),
  },
  {
    path: "/platforms",
    element: (
      <Layout title="Platforms" favicon="../img/logo.png">
        <Platforms />
      </Layout>
    ),
  },
  {
    path: "/contact",
    element: (
      <Layout title="Contact Us" favicon="../img/logo.png">
        <Contact />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Signup />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/email-verify",
    element: <VerifyEmail />,
  },
  {
    path: "/verified",
    element: <Verified />,
  },
]);

export default router;
