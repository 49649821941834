import "../css/login.css";
import { useLocation } from "react-router-dom";
export default function VerifyEmail() {
  const location = useLocation();
  const email = location.state?.email || "your email";
  return (
    <section className="login">
      <div className="login-inner">
        <h1>Verify your email</h1>
        <p>
          A link has been sent to <b>{email}</b>, click on the link to verify
          your email address or paste the URL to verify
        </p>
        <p>
          Didn't receive link? <a href="/">Resend</a>
        </p>

        <p className="acc">
          Go back to{" "}
          <span style={{ color: "#6666ff;" }}>
            <a href="/sign-up">Sign up</a>
          </span>
        </p>
      </div>
    </section>
  );
}
