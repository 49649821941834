import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";

const Index: React.FC = () => {
  return (
    <div className="">
      <section className="hero">
        <h1>Empowering Excellence, Celebrating You</h1>
        <p>
          Be the HERO through excellence, engagement, and productivity with our
          employee recognition platform.
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          <Link to={"https://app.getxhero.com/login"} className="explore">
            Join Us
          </Link>
          <a href="#" className="explore">
            Watch Demo
          </a>
        </div>
      </section>
      <section className="h">
        <h3>Trusted and loved by 100+ businesses around the industries.</h3>
      </section>
      <section className="brands">
        <div className="brand-list">
          <svg
            width="90"
            height="29"
            viewBox="0 0 90 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_55_1339)">
              <path
                d="M87.1967 17.7208L89.6934 19.3553C88.883 20.5274 86.9448 22.5383 83.594 22.5383C79.4329 22.5383 76.334 19.3768 76.334 15.3551C76.334 11.0753 79.4658 8.17188 83.2436 8.17188C87.0434 8.17188 88.9049 11.1398 89.5072 12.742L89.8357 13.5593L80.0461 17.538C80.7908 18.979 81.9515 19.7102 83.594 19.7102C85.2366 19.7102 86.3754 18.9144 87.1967 17.7208ZM79.5205 15.1293L86.0579 12.4625C85.6965 11.5699 84.6234 10.9355 83.3422 10.9355C81.7106 10.9355 79.4439 12.3549 79.5205 15.1293Z"
                fill="#FF302F"
              />
              <path
                d="M71.6152 0.847656H74.7689V21.8812H71.6152V0.847656Z"
                fill="#20B15A"
              />
              <path
                d="M66.6421 8.73008H69.6863V21.505C69.6863 26.8064 66.4998 28.9893 62.7329 28.9893C59.185 28.9893 57.0497 26.6451 56.2503 24.7418L59.0426 23.6019C59.5463 24.774 60.7618 26.1612 62.7329 26.1612C65.1529 26.1612 66.6421 24.688 66.6421 21.9352V20.9028H66.5326C65.8099 21.7631 64.4302 22.5373 62.6781 22.5373C59.0207 22.5373 55.6699 19.4081 55.6699 15.3756C55.6699 11.3216 59.0207 8.16016 62.6781 8.16016C64.4192 8.16016 65.8099 8.92364 66.5326 9.7624H66.6421V8.73008ZM66.8611 15.3756C66.8611 12.8379 65.1419 10.9883 62.9519 10.9883C60.7399 10.9883 58.8784 12.8379 58.8784 15.3756C58.8784 17.8812 60.7399 19.6985 62.9519 19.6985C65.1419 19.7092 66.8611 17.8812 66.8611 15.3756Z"
                fill="#3686F7"
              />
              <path
                d="M38.3581 15.3229C38.3581 19.4629 35.073 22.5061 31.0433 22.5061C27.0136 22.5061 23.7285 19.4522 23.7285 15.3229C23.7285 11.1613 27.0136 8.12891 31.0433 8.12891C35.073 8.12891 38.3581 11.1613 38.3581 15.3229ZM35.1606 15.3229C35.1606 12.7421 33.2553 10.9678 31.0433 10.9678C28.8314 10.9678 26.926 12.7421 26.926 15.3229C26.926 17.8822 28.8314 19.678 31.0433 19.678C33.2553 19.678 35.1606 17.8822 35.1606 15.3229Z"
                fill="#FF302F"
              />
              <path
                d="M54.3347 15.3551C54.3347 19.4951 51.0496 22.5383 47.0199 22.5383C42.9902 22.5383 39.7051 19.4951 39.7051 15.3551C39.7051 11.1936 42.9902 8.17188 47.0199 8.17188C51.0496 8.17188 54.3347 11.1828 54.3347 15.3551ZM51.1262 15.3551C51.1262 12.7743 49.2209 11 47.0089 11C44.797 11 42.8916 12.7743 42.8916 15.3551C42.8916 17.9144 44.797 19.7102 47.0089 19.7102C49.2318 19.7102 51.1262 17.9036 51.1262 15.3551Z"
                fill="#FFBA40"
              />
              <path
                d="M11.7053 19.3882C7.11716 19.3882 3.52546 15.7536 3.52546 11.2479C3.52546 6.74229 7.11716 3.10767 11.7053 3.10767C14.1801 3.10767 15.9869 4.06471 17.3228 5.29059L19.5239 3.12917C17.6623 1.37638 15.1766 0.0429688 11.7053 0.0429688C5.41986 0.0429688 0.130859 5.07552 0.130859 11.2479C0.130859 17.4203 5.41986 22.4529 11.7053 22.4529C15.0999 22.4529 17.6623 21.3561 19.6662 19.3129C21.7249 17.2913 22.36 14.4524 22.36 12.1512C22.36 11.4307 22.2724 10.6888 22.1738 10.1403H11.7053V13.1298H19.1625C18.9435 15.0008 18.3412 16.2805 17.4543 17.1515C16.3811 18.2161 14.6838 19.3882 11.7053 19.3882Z"
                fill="#3686F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_55_1339">
                <rect width="90" height="29" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <img
            src={require("../img/first bank.png")}
            alt="Firstbank"
            className="brand-items"
          />
          <img
            src={require("../img/coke.png")}
            alt="Cocacola"
            className="brand-items"
          />
          <img
            src={require("../img/amazon.png")}
            alt="amazon"
            className="brand-items"
          />
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="build">
        <div className="builds">
          <h1>Build a Team of Groundbreakers</h1>
          <p>
            At Xhero, we are dedicated and commited to acknowledging and
            appreciating the efforts, achievements and contributions of
            employees.
          </p>
        </div>

        <div className="build-list">
          <div className="build-items">
            <img src={require("../img/star.png")} alt="star" />
            <h3>Social Recognition</h3>
            <p>
              Recognizing your employees' hard work and contributions is crucial
              for building a positive, engaged workplace. xhero makes it easy to
              share wins, milestones, and shoutouts with the whole team,
              fostering a culture of appreciation. A little social recognition
              can go a long way in boosting morale and making your employees
              feel valued.
            </p>
          </div>
          <div className="build-items">
            <img src={require("../img/scale.png")} alt="scale" />
            <h3>Improving productivity through Data-Informed Decisions</h3>
            <p>
              We bring together all the important data from reviews, sales
              reports, and customer feedback to make better decisions. This
              helps us spot areas for improvement easily. Our reports are clear,
              accessible, and straightforward.
            </p>
          </div>
          <div className="build-items">
            <img src={require("../img/trade.png")} alt="trade" />
            <h3>Engagement</h3>
            <p>
              Empower your company to show genuine appreciation for employees
              which can help foster a culture where every team member feels
              valued and recognized for their contributions.
            </p>
          </div>
        </div>
        <svg
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="#0D0D0D"
          />
        </svg>
      </section>
      <section className="people">
        <h3>Prioritizing Employee Wellbeing for a Thriving Company</h3>
        <div className="properties">
          <img src={require("../img/unity.png")} alt="unity" />
          <div className="prop">
            <p>
              Healthy, happy employees drive company success. We offer an
              engaging wellbeing program with fun challenges, on-demand content,
              and support for mental, physical, and financial wellness.
            </p>
            <Link to={"https://app.getxhero.com/register"} className="explore">
              Explore Platform
            </Link>
          </div>
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="built">
        <h3>Built-in Integration</h3>
        <div className="builts">
          <div className="built-txt">
            <p>
              Our platform works with your current systems, so you don't need to
              worry about manual processes or replacing your existing software.
              We integrate seamlessly with your HRIS and payroll systems,
              enhancing your employees' experience without disrupting your
              current tech setup.
            </p>
            <Link to={"https://app.getxhero.com/register"} className="learn">
              Learn More <i className="fa fa-arrow-right"></i>
            </Link>
          </div>
          <img src={require("../img/brands.png")} alt="brands" />
        </div>
        <svg
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="#0D0D0D"
          />
        </svg>
      </section>
      <section className="experience">
        <h3>Experience it through the Word</h3>
        <div className="exp-list">
          <div className="exp-items">
            <h3>Sarah A.</h3>
            <span>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </span>
            <p>"Rewarding Experience Platform"</p>
            <p>
              "This platform is fantastic! It provides incentives that truly
              inspire me to get involved. The rewards are meaningful and make me
              feel valued as a participant. It's not just about the perks; It's
              about the engaging experience it creates. I highly recommend it."
            </p>
            <img src={require("../img/icon.png")} alt="icon" />
          </div>
          <div className="exp-items">
            <h3>Sarah A.</h3>
            <span>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </span>
            <p>"Rewarding Experience Platform"</p>
            <p>
              "This platform is fantastic! It provides incentives that truly
              inspire me to get involved. The rewards are meaningful and make me
              feel valued as a participant. It's not just about the perks; It's
              about the engaging experience it creates. I highly recommend it."
            </p>
            <img src={require("../img/icon.png")} alt="icon" />
          </div>
          <div className="exp-items">
            <h3>Sarah A.</h3>
            <span>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </span>
            <p>"Rewarding Experience Platform"</p>
            <p>
              "This platform is fantastic! It provides incentives that truly
              inspire me to get involved. The rewards are meaningful and make me
              feel valued as a participant. It's not just about the perks; It's
              about the engaging experience it creates. I highly recommend it."
            </p>
            <img src={require("../img/icon.png")} alt="icon" />
          </div>
        </div>
      </section>
      <svg viewBox="0 0 1440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 42.1023H1090.08C833.336 42.1023 580.229 95 360 95C139.771 95 0 42.1023 0 42.1023V0H1440V42.1023Z"
          fill="#0D0D0D"
        />
      </svg>
      <section className="built">
        <div className="ready">
          <img src={require("../img/Rectangle 4367.png")} alt="brands" />
          <div className="ready-txt">
            <h2>Ready to Uncover the Potentials of your Platform?</h2>
            <p>
              Let's embark on a journey together to unleash its full potential,
              maximizing its capabilities and optimizing outcomes. Together, we
              can transform your platform into a powerhouse of innovation and
              success.
            </p>
            <Link to={"https://app.getxhero.com/register"} className="learns">
              Learn More
            </Link>
          </div>
        </div>
        <svg
          viewBox="0 0 1440 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64.5909H349.922C606.664 64.5909 859.771 -7.62939e-06 1080 -7.62939e-06C1300.23 -7.62939e-06 1440 64.5909 1440 64.5909V116H0V64.5909Z"
            fill="#0D0D0D"
          />
        </svg>
      </section>
    </div>
  );
};

export default Index;
