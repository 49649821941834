import React, { useState, useEffect } from "react";
import "../css/login.css";
import toastr from "toastr";
import { authService } from "../services/authService";
import { LoginData } from "../types/Signup";
import LoadingModal from "./layouts/Loading";
export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<LoginData>({
    email: "",
    password: "",
  });

  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (type === "checkbox") {
      setRememberMe(checked);
    }
  };

  useEffect(() => {
    const savedSession = localStorage.getItem("session");
    if (savedSession) {
      const sessionData = JSON.parse(savedSession);
      if (new Date(sessionData.expiry) > new Date()) {
        toastr.success("Session restored successfully");
        console.log(sessionData.user);
      } else {
        localStorage.removeItem("session");
      }
    }
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const { email, password } = formData;

    try {
      const response = await authService.login({
        email,
        password,
      });
      if (response.status === 200) {
        toastr.success("Login in successful");
        if (rememberMe) {
          const sessionData = {
            user: response.user,
            token: response.token,
            expiry: new Date(
              Date.now() + 15 * 24 * 60 * 60 * 1000
            ).toISOString(),
          };
          localStorage.setItem("session", JSON.stringify(sessionData));
        }
      }
      console.log(response);
    } catch (error) {
      if (error instanceof Error) {
        toastr.error("Login in failed", error.message);
      } else {
        console.error("Login in failed", error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <section className="login">
      <div className="login-inner">
        <a href="/">
          <img src={require("../img/logo-1.png")} alt="" />
        </a>
        <h1>Login</h1>
        <p>Kindly fill up your details below</p>
        <form onSubmit={handleSubmit}>
          <span className="logs">
            <label>Enter Your email address</label>
            <input
              type="email"
              placeholder="123@gmail.com"
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input1"
            />
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="........"
              required
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="input2"
              id="password"
            />
            <span className="show-password" onClick={togglePassword}>
              <i
                className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                id="login-eye"
              ></i>
            </span>
          </span>

          <div className="forgot">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={handleChange}
            />
            <label>Remember me for 15 days</label>
            <a href="/forgot-password">Forgot Password</a>
          </div>
          <button type="submit" className="log">
            Login
          </button>
        </form>
        <p className="acc">
          Don't have an account? <a href="/sign-up">Sign up</a>
        </p>
      </div>

      <LoadingModal isLoading={isLoading} />
    </section>
  );
}
