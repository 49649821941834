import React from "react";
import { Helmet } from "react-helmet";

interface Header {
  title: string;
  favicon: string;
}

const Head: React.FC<Header> = ({ title, favicon }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="Be the HERO through excellence, engagement, and productivity with our employee recognition platform."
      />
      <meta
        name="keywords"
        content="employee recognition, productivity, excellence, engagement"
      />
      {favicon && <link rel="icon" href={favicon} />}
    </Helmet>
  );
};

export default Head;
