import "../css/login.css";
import React, { useState } from "react";
import { authService } from "../services/authService";
import { ForgotPasswordInterface } from "../types/Signup";
import { handleChange } from "../utils/formHelper";
import toastr from "toastr";
import LoadingModal from "./layouts/Loading";
export default function ForgotPassword() {
  const [formData, setFormData] = useState<ForgotPasswordInterface>({
    email: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    setIsLoading(true);

    const { email } = formData;

    try {
      const response = await authService.forgotPassword({
        email,
      });
      if (response.status === 200) {
        toastr.success("Password Sent Successfully, Check your mail");
      }
      console.log(response);
    } catch (error) {
      if (error instanceof Error) {
        toastr.error("An error Occured", error.message);
      } else {
        console.error("An error Occurred", error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section className="login-forgot">
      <div className="login-inner">
        <h1>Forgot password</h1>
        <p>
          please be informed not to bother, a reset information would be sent to
          your email
        </p>
        <form onSubmit={handleSubmit}>
          <span className="logs">
            <label>Enter Your email address</label>
            <input
              type="email"
              placeholder="123@gmail.com"
              required
              className="input1"
              name="email"
              value={formData.email}
              onChange={(e) => handleChange(e, formData, setFormData)}
            />
          </span>

          <button type="submit" className="log" style={{ marginTop: "2%" }}>
            Submit
          </button>
        </form>
        <p className="acc">
          Don't have an account?<a href="/sign-up">Sign up</a>
        </p>
      </div>

      <LoadingModal isLoading={isLoading} />
    </section>
  );
}
