import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="footer-top">
          <img src={require("../../img/logo.png")} alt="logo" />
          <div className="f-links">
            <Link to={"/about"}>About Us</Link>
            <Link to={"/pricing"}>Pricing</Link>
            <Link to={"/contact"}>Contact Us</Link>
            <Link to={"/faq"}>FAQ</Link>
            <a href="#">Privacy</a>
            <a href="#">T&C</a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 xHero-A Registered Trademark of Africa</p>
          <div className="social">
            <a href="#">
              <img src={require("../../img/Facebook.png")} alt="Facebook" />
            </a>
            <a href="#">
              <img src={require("../../img/Twitter.png")} alt="Twitter" />
            </a>
            <a href="#">
              <img src={require("../../img/Instagram.png")} alt="Instagram" />
            </a>
            <a href="#">
              <img src={require("../../img/mdi_youtube.png")} alt="YouTube" />
            </a>
            <a href="#">
              <img src={require("../../img/LinkedIn.png")} alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
