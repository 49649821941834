import { Link } from "react-router-dom";
import "../../css/style.css";
import React, { useState } from "react";
export default function Header() {
  const [isLinkVisible, setIsLinkVisible] = useState(false);

  const toggleMenu = () => {
    setIsLinkVisible(!isLinkVisible);
  };

  return (
    <header>
      <div className="navbar">
        <a href="/">
          <img
            src={require("../../img/logo1.png")}
            width={220}
            height={60}
            alt="logo"
            style={{ marginTop: "5px" }}
          />
        </a>
        <div className={`links ${isLinkVisible ? "show" : ""}`} id="link">
          <ul>
            <li>
              <Link to={"/platforms"}>Platform</Link>
            </li>
            <li className="user">
              <a href="/">
                Resources <i className="fa fa-caret-down"></i>
              </a>
              <div className="ls">
                <Link to={"/faq"}>FAQ</Link>
                <Link to={"/blog"}>Blog</Link>
                <a href="#">Api Documentation</a>
              </div>
            </li>
            {/* <li>
              <Link to={"/pricing"}>Pricing</Link>
            </li> */}
            <li className="user">
              <a href="/">
                Company <i className="fa fa-caret-down"></i>
              </a>
              <div className="ls">
                <Link to={"/about"}>About</Link>
                <Link to={"/contact"}>Contact us</Link>
              </div>
            </li>
          </ul>
          <div className="sign">
            <Link to={"https://app.getxhero.com/login"}>Log In</Link>
            <Link to={"/contact"}>Book a demo</Link>
          </div>
        </div>
        <button id="open" onClick={toggleMenu}>
          <i className={`fas ${isLinkVisible ? "fa-times" : "fa-bars"}`}></i>
        </button>
      </div>
    </header>
  );
}
